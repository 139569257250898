import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { of } from 'rxjs';
import { AddReferenceComponent } from '../add-reference/add-reference.component';

@Component({
    selector: 'app-update-brick',
    templateUrl: './update-brick.component.html',
    styleUrls: ['./update-brick.component.scss'],
})
export class UpdateBrickComponent implements OnInit {

    brickForm: FormGroup;
    brick_key: any;
    brick: any;
    brick_image: any = false;
    presentationName: any;
    presentations: any = [];
    category: any;
    type: any = 'brick';
    recommended: any = [];

    tinyConfs: any = {
        height: 500,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        importcss_append: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'sliding',
        contextmenu: "link table",
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        ],
    }

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController
    ) {
        this.brick = navParams.get('brick');
        if (navParams.get('type')) {
            this.type = navParams.get('type');
        }

        this.brickForm = this.formBuilder.group({
            name: [this.brick.name, [Validators.required]],
            description: [this.brick.description, [Validators.required]],
            visible: [this.brick.visible, [Validators.required]],
            type: ['brick', [Validators.required]],
            position: [this.brick.position],
            supplier: [this.brick.supplier],
            bar_code: [this.brick.bar_code],
            free_delivery: [this.brick.free_delivery],
        });

        if (this.type == 'brick') {
            this.api.getRef(`bricks/${this.brick.$key}/presentations`).ref
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let presentation = element.doc.data();
                            presentation.$key = element.doc.id;
                            this.presentations.push(presentation);
                        }
                    });
                })
        }
        this.getReferences();
    }

    ngOnInit() { }

    getReferences() {
        this.recommended = [];
        this.api.getRef(`recommended/`).ref
            .where('brick', '==', this.brick.$key)
            .get()
            .then(snapshots => {
                snapshots.forEach(element => {

                    let recommended = element.data();
                    recommended.$key = element.id;
                    this.api.getDocument('bricks', element.data().reference).then(data => {
                        recommended.brick = data;
                        this.recommended.push(recommended);
                    });

                });
            })
    }


    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }

    addBrick() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.brick.name = this.brickForm.value.name;
                this.brick.description = this.brickForm.value.description;
                this.brick.visible = this.brickForm.value.visible;
                this.brick.position = this.brickForm.value.position;
                this.brick.bar_code = this.brickForm.value.bar_code;
                this.brick.supplier = this.brickForm.value.supplier;
                this.brick.free_delivery = this.brickForm.value.free_delivery;

                this.api.getRef('bricks').ref
                    .where('brick', '==', this.brick.$key)
                    .where('type', '==', 'reference')
                    .get()
                    .then(data => {
                        data.forEach(element => {
                            this.api.updateDocument('bricks', element.id, {
                                visible: this.brick.visible
                            })
                        });
                    })

                this.api.updateBrick(this.brick, this.presentations, this.brick_image.src).then(data => {
                    loader.dismiss();
                    Swal.fire('¡Listo!', 'Tu Brick ha sido agregado', 'success');
                    this.modalController.dismiss();
                });
            })
        })
    }

    addPresentation() {
        this.api.addDocument(`bricks/${this.brick.$key}/presentations`, {
            presentation: this.presentationName,
            price: 0,
        }).then(data => {
            this.presentationName = '';
        })

    }

    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.main = true;

    }

    removePresentation(presentation) {
        Swal.fire({
            title: 'Esta seguro que deseas eliminar?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {
                this.api.deleteDocument(`bricks/${this.brick.$key}/presentations/`, presentation.$key).then(data => {
                    for (let index = 0; index < this.presentations.length; index++) {
                        const element = this.presentations[index];
                        if (element.$key == presentation.$key) {
                            this.presentations.splice(index, 1)
                        }
                    }
                    Swal.fire(
                        'Eliminado!',
                        'La presentación ha sido eliminada',
                        'success'
                    )
                });
            }
        })
    }

    async addReference() {
        const modal = await this.modalController.create({
            component: AddReferenceComponent,
            cssClass: 'addModal',
            componentProps: {
                category: this.category,
            }
        });
        modal.onDidDismiss().then(data => {
            if (data.data) {
                let brick = {
                    brick: this.brick.$key,
                    visible: data.data.visible,
                    reference: data.data.$key
                }
                console.log(brick);

                this.loader().then(loader => {
                    loader.present().then(() => {
                        this.api.addDocument('recommended', brick).then(data => {
                            loader.dismiss();
                            this.getReferences();
                            Swal.fire('¡Listo!', 'Tu Referencia ha sido agregada', 'success');
                        });
                    })
                })


            }

        })
        return await modal.present();
    }

    deleteReference(reference) {
        this.api.deleteDocument('recommended', reference.$key).then(data => {
            this.getReferences();
        })
    }

}
