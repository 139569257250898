import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';
import 'firebase/storage'
import { LoadingController } from '@ionic/angular';
declare var google;

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        public db: AngularFirestore,
        public loadingCtrl: LoadingController,
    ) {

    }
    ///////////////////////////////
    // GENERIC CRUD API REQUESTS
    ///////////////////////////////
    getAllDocuments(collection: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collection)
                .get()
                .toPromise()
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var obj = JSON.parse(JSON.stringify(doc.data()));
                        obj.$key = doc.id
                        arr.push(obj);
                    });

                    if (arr.length > 0) {
                        resolve(arr);
                    } else {
                        resolve(null);
                    }


                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getDocument(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    resolve(doc);
                }).catch((error: any) => {
                    reject(error);
                });
        })
        // return this.firestore.collection('songList').doc(songId);
    }

    deleteDocument(collectionName: string, docID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .delete()
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    addDocument(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collectionName).add(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .update(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    setDocument(collectionName: string, docID: string, dataObj: any) {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .set(dataObj)
                .then((obj: any) => {
                    resolve(obj)
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    getRef(collection) {
        return this.db.collection(collection);
    }

    ///////////////////////////////
    /////////////CUSTOM API REQUESTS
    ///////////////////////////////


    addExplore(brick_data, presentations, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            brick_data.created_at = new Date();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve();
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve();
                })
            }
        });
    }

    addBrick(brick_data, presentations, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            brick_data.created_at = new Date();

            presentations.forEach(element => {
                let presentation_key = this.db.createId();
                batch.set(this.db.firestore.collection(`bricks/${brick_key}/presentations/`).doc(presentation_key),
                    element
                );
            });

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`bricks/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`bricks`).doc(brick_key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve();
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`bricks`).doc(brick_key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve();
                })
            }
        });
    }

    uploadSectionImage(brick, image) {
        return new Promise((resolve, reject) => {
            console.log(brick);

            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`categories/${brick}/${filename}.png`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        console.log(data);

                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    uploadBrickImage(brick, image) {
        return new Promise((resolve, reject) => {
            console.log(brick);

            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`categories/${brick}/${filename}.png`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        console.log(data);

                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    updateBrick(brick_data, presentations, image) {
        return new Promise((resolve, reject) => {
            console.log(brick_data);

            let batch = this.db.firestore.batch();

            presentations.forEach(element => {
                batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key),
                    element
                );
            });

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`bricks/${brick_data.category}/${brick_data.$key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.update(this.db.firestore.collection(`bricks`).doc(brick_data.$key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve();
                            })
                        });
                    })

            } else {
                batch.update(this.db.firestore.collection(`bricks`).doc(brick_data.$key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve();
                })
            }
        });
    }


}


