import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-add-brick',
    templateUrl: './add-brick.component.html',
    styleUrls: ['./add-brick.component.scss'],
})
export class AddBrickComponent implements OnInit {

    brickForm: FormGroup;
    brick_key: any;
    brick: any;
    brick_image: any = false;
    presentationName: any;
    presentations: any = [];
    category: any;
    type: any = String('brick');
    imageBrick: any;

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
    ) {
        let category = navParams.get('category');

        if (navParams.get('type') !== undefined) {
            this.type = navParams.get('type');
        }

        this.type = 'brick';
        let name = navParams.get('name');

        this.brickForm = this.formBuilder.group({
            name: [name, [Validators.required]],
            description: ['', [Validators.required]],
            visible: [true, [Validators.required]],
            category: [category.$key, [Validators.required]],
            type: [this.type, [Validators.required]],
            image_temp: [''],
            order: [''],
            supplier: [''],
            bar_code: [''],
        });
    }

    ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }

    addBrick() {

        this.loader().then(loader => {
            loader.present().then(() => {
                let brickData = this.brickForm.value;
                brickData.position = 0;
                if (brickData.image_temp) {
                    brickData['image'] = {};
                    brickData['image']['downloadURL'] = brickData.image_temp;
                }
                delete brickData.image_temp;

                this.api.addBrick(brickData, this.presentations, this.brick_image.src).then(data => {
                    loader.dismiss();
                    Swal.fire('¡Listo!', 'Tu Brick ha sido agregado', 'success');
                    this.modalController.dismiss();
                });
            })
        })

    }


    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.main = true;

    }

    addPresentation() {
        this.presentations.push({
            presentation: this.presentationName,
            price: 0,
        });
        this.presentationName = '';
    }

    removePresentation(presentation) {
        for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.presentation == presentation.presentation) {
                this.presentations.splice(index, 1)
            }
        }

    }

}
