import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { firebaseConfig } from './../environments/environment'
import { ImageUploadModule } from 'angular2-image-upload';
import { UpdateBrickComponent } from './components/update-brick/update-brick.component';

import { EditorModule } from '@tinymce/tinymce-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddBrickComponent } from './components/add-brick/add-brick.component';
import { AddReferenceComponent } from './components/add-reference/add-reference.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
    declarations: [
        AppComponent,
        UpdateBrickComponent,
        AddBrickComponent,
        AddReferenceComponent
    ],
    entryComponents: [
        UpdateBrickComponent,
        AddBrickComponent,
        AddReferenceComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: 'Atrás',
            mode: 'ios'
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        HttpClientModule,
        ImageUploadModule.forRoot(),
        EditorModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
