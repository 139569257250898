import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-add-reference',
    templateUrl: './add-reference.component.html',
    styleUrls: ['./add-reference.component.scss'],
})
export class AddReferenceComponent implements OnInit {

    bricks: any = [];
    loaded: any = false;

    constructor(
        public api: ApiService,
        public modalController: ModalController
    ) {
        this.api.getRef('bricks').ref
            .where('type', '==', 'brick')
            .orderBy('name')
            .get()
            .then(data => {
                data.forEach(element => {
                    let brick = element.data();
                    brick.$key = element.id;
                    this.bricks.push(brick);
                });
                this.loaded = true;

            })

    }

    ngOnInit() { }

    selectBrick(brick) {
        this.modalController.dismiss(
            brick
        )
    }

}
